/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import smoothscroll from 'smoothscroll-polyfill';
import React from "react"

import { ModalRenderer } from 'src/utils/modal';
import Footer from 'src/components/footer';

import Nav from './nav';

import './global.css';
import s from "./layout.module.css"


if (typeof window !== 'undefined') smoothscroll.polyfill();
/*
const variants = {
  init: {
    position: 'static',
    overflow: 'visible',
    top: 'auto',
    width: 'auto',
    height: 'auto',
    opacity: 0,
    paddingRight: 0,
    // scale: 1.1,
    // transformOrigin: 'top 50vh center',
  },
  normal: {
    position: 'static',
    overflow: 'visible',
    top: 'auto',
    width: 'auto',
    height: 'auto',
    opacity: 1,
    paddingRight: 0,
    // scale: 1,
    // transformOrigin: 'top 50vh center',
  },
  exit: () => ({
    position: 'absolute',
    overflow: 'hidden',
    top: -window.scrollY + 100,
    width: '100vw',
    height: `calc(100vh + ${window.scrollY - 100}px)`,
    opacity: 0,
    paddingRight: window.scrollbars.visible ? 8 : 0,
    // scale: 0.9,
    // transformOrigin: 'bottom center',
  })
}
*/

const Layout = (props) => {
  const { children } = props;
  
  return (
    <div className={s['container']}>
      <Nav {...props} />
      <main>
        <div id="top" />
            <div className={s['content']}>
              {children}
            </div>
          <ModalRenderer />
      </main>
      <div className={s['fill']} />
      <footer className={s['footer']}>
        <Footer />
      </footer>
    </div>
  )
}

export default Layout

        // <AnimatePresence>
        //   <motion.div
        //     className={s['fader']}
        //     initial="init"
        //     animate="normal"
        //     exit="exit"
        //     variants={variants}
        //     key={pathname}
        //     transition={{ 
        //       opacity: { duration: .2 },
        //       scale: { duration: .2 },
        //       default: { duration: 0 },
        //     }} 
        //   >
          //   </motion.div>
          // </AnimatePresence>