import React from 'react';
import { Link } from 'gatsby';

import LogoImage from 'src/assets/logo.svg';
import Prelead from 'src/assets/prelead.svg';

export const Logo = ({ TwoLines, height:_h=50 }) => {
  const height = isNaN(_h) ? _h : `${_h}px`
  
  return <Link to="/#top" style={{
    display: 'flex',
    flexDirection: TwoLines ? 'column' : 'row',
    width: `calc(${height}*5)`
  }}>
    <img src={LogoImage} alt="Logo" style={{
      height,
      margin: TwoLines ? `0 0 calc(${height}*0.3) 0` : `0 calc(${height}*0.3) 0 0`,
    }} />
    <img src={Prelead} alt="Prelead" style={{
      height: TwoLines ? `calc(${height}*0.3)` : height
    }} />
  </Link>
}