import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'gatsby'
import clsx from 'clsx'

import askDemo from 'src/utils/ask-demo'

import { FillAbs, Relative, Logo, Button } from 'src/styled-components'

import s from './nav.module.css'

const Nav = ({ location }) => {
  const isHome = location.pathname === '/'
  
  const [ menuOpen, setMenuOpen ] = useState(false)
  
  const handleMenuClick = useCallback(() => {
    setMenuOpen(menu => !menu)
  }, [ setMenuOpen ])
  
  useEffect(() => {
    setMenuOpen(false);
  }, [ setMenuOpen, location.hash, location.pathname ])
  
  const containerStyle = menuOpen ? {
    height: 'calc(100px + 22em)'
  } : null
  
  const classNames = clsx([
    s['container'],
    isHome && s['isHome'],
    menuOpen && s['menuOpen'],
  ])
  
  return <div
    key={location.pathname}
    className={classNames}
    style={containerStyle}
  >
    <FillAbs className={s['shadow']} />
    <Relative Component="nav" className={s['navContainer']}>
      <div className={s['logo']}>
        <Logo height={30} />
      </div>
      <Button className={s['menuBtn']} onClick={handleMenuClick}>Menu</Button>
      <nav className={s['linksContainer']}>
        <Link to="/#top">Accueil</Link>
        <Link to="/#features">Fonctionnalités</Link>
        <Link to="/#price">Tarifs</Link>
        <Link to="/#guides">Guide de la prospection efficace</Link>
        <Button onClick={askDemo}>Démo !</Button>
        <a href="//app.prelead.fr">Se connecter</a>
      </nav>
    </Relative>
  </div>
}

export default Nav