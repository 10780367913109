import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Formik, Form as FormikForm, Field, ErrorMessage, useFormikContext } from 'formik';

import { Button } from './buttons';
import s from './form.module.css';

const LabeledField = ({ name, autofocus, type='text', label, labelProps={}, ...p }) => {
  const ref = useRef();
  useEffect(() => autofocus && ref.current.focus(), []); // eslint-disable-line
  
  return <label ref={ref} {...labelProps}>
    <Field type={type} name={name} {...p} />
    <span className={s['label']}>{label}</span>
    <ErrorMessage name={name} component="span" />
  </label>
}

const Textarea = p => {
  const { handleChange } = useFormikContext()
  const [ height, setH ] = useState('5em')
  
  const handleAutoGrow = useCallback(e => {
    const target = e.target;
    setTimeout(() => setH(Math.max(target.scrollHeight, 50)), 0);
    handleChange(e);
  }, [ handleChange, setH]);
  
  return <LabeledField
    {...p}
    onChange={handleAutoGrow}
    style={{ height }}
  />
}


export const Form = ({ schema, action, btnLabel='Envoyer', btnProps, ...p }) => {
  
  const INITIAL = () => {
    return schema.reduce((out, f) => {
      out[f.name] = typeof f.defaultValue !== 'undefined' ? f.defaultValue : '';
      return out
    }, {})
  }
  
  return <Formik
    initialValues={INITIAL()}
    {...p}
  >
    {({ isSubmitting, submitForm }) => {
      const submit = e => {
        e.preventDefault()
        submitForm()
      }
      
      return <FormikForm action={action} noValidate className={s['form']} onSubmit={submit}>
        {schema.map(f => {
          switch (f.component) {
            case 'textarea':
              return <Textarea key={f.name} {...f} />
            case 'hidden':
              return <Field 
                key={f.name}
                {...f}
                defaultValue={undefined}
                type="hidden"
                component='input' />
            default:
              return <LabeledField key={f.name} {...f} />
          }
        })}
        <Button {...btnProps} type="submit" disabled={isSubmitting}>
          {btnLabel}
        </Button>
      </FormikForm>
    }}
  </Formik>
}