import React from 'react';
import { render } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import s from './flash.module.css';

let ref = null;

export default async function FlashService (message, options) {
  if (typeof document === `undefined`) return false;
  
  if (!ref) {
    const portal = document.createElement('div');
    document.body.appendChild(portal);
    ref = render(<FlashContainer />, portal);
  }
  
  return ref.addFlash(message, options);
}

class FlashContainer extends React.Component {
  constructor() {
    super();
    this.state ={ stack: [] };
    this.cpt = 0;
  }
  
  async addFlash(message, _options) {
    const options = {
      duration: 5,
      type: typeof _options === 'string' ? _options : 'info',
    };
    if (typeof _options === 'object') Object.assign(options, _options);
    
    
    const flash = {
      id: ++this.cpt,
      options,
      message,
      timeout: setTimeout(() => this.handleDismiss(flash), options.duration*1000),
    };
    
    this.setState({ stack: this.state.stack.concat([ flash ])})
  }
  
  handleDismiss(flash) {
    const index = this.state.stack.indexOf(flash);
    if (index > -1) {
      clearTimeout(flash.timeout);
      const stack = this.state.stack.slice();
      stack.splice(index, 1);
      this.setState({ stack });
    }
  }
  
  render() {
    return <div className={s['container']}>
      <AnimatePresence>
        {this.state.stack.map(flash => (
          <motion.div
            key={flash.id}
            positionTransition
            initial={{ opacity: 0, scale: 0 }}
            exit={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            className={clsx([s['flash'], s[flash.options.type+'Flash']])}
            onClick={this.handleDismiss.bind(this, flash)}
          >
            {flash.message}
            <span className={s['close']} aria-label="close">✕</span>
            <motion.div
              className={s['bar']}
              initial={{ scaleX: 1 }}
              animate={{ scaleX: 0 }}
              transition={{ duration: flash.options.duration }}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  }
}