import React, { useCallback } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby'

import { Form } from 'src/styled-components';
import Flash from 'src/utils/flash';
import AnimLogo from 'src/assets/anim-logo.gif';

import s from './footer.module.css';

/* global fetch */

const Footer = ({ action='#contact' }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site { siteMetadata { contactAction } }
        prismicFooter { data {
          title { text }
          subtitle { text }
          content { html }
          columns {
            ... on PrismicFooterColumnsColumn {
              items {
                link_label { text }
                link { uid }
              }
              primary {
                name { text }
              }
            }
          }
        } }
      }
    `
  )
  const { contactAction } = data.site.siteMetadata;
  const { content, title, subtitle, columns } = data.prismicFooter.data;
  
  const handleSubmit = useCallback(async (values, { setSubmitting, resetForm }) => {
    window.gtag && window.gtag('event', values.title, {
      'event_category': 'Forms',
      'event_label': 'Envoi du formulaire de contact',
    });
    
    if (typeof fetch === 'undefined') {
      Flash(<div>
        <p>Votre navigateur est vraiment très agé !</p>
      </div>, { type:'error', duration: 10 })
      return
    }
    
    const response = await fetch(contactAction, {
  		method: 'POST',
  		body: JSON.stringify(values),
  		headers: {
  			'content-type': 'application/json'
  		}
  	});
  
  	if (!response.ok) {
      Flash(<div>
        <p>Erreur lors de l'envoi du formulaire</p>
      </div>, { type:'error', duration: 10 });
  	} else {
      resetForm();
      Flash(<div>
        <p>Votre message a bien été envoyé</p>
        <p>Nous allons vous contacter rapidement.</p>
      </div>, { duration: 10 });
  	}
  }, [ contactAction ]);
  
  return <div className={s['contact']}>
    <header>
      <h2>{title.text}</h2>
      <h3>{subtitle.text}</h3>
    </header>
    <div className={s['contactForm']}>
      <div className={s['intro']}>
        <img src={AnimLogo} alt="Anim logo" />
        <div dangerouslySetInnerHTML={{ __html: content.html }} />
      </div>
      <Form schema={[
          {
            name: 'title',
            component: 'hidden',
            defaultValue: 'Footer Contact'
          },{
            label: 'Prénom',
            name: 'firstname',
          },{
            label: 'Nom',
            name: 'lastname',
          },{
            label: 'Adresse email',
            name: 'mail',
          },{
            label: 'Téléphone',
            name: 'phone',
          },{
            label: 'Message',
            name: 'message',
            component: 'textarea',
            labelProps: {
              style: {
                gridColumnEnd: 'span 2',
                height: 'auto',
              }
            }
          },
        ]}
        action="#contact"
        btnLabel="Envoyer !"
        btnProps={{
          style: { gridColumnEnd: 'span 2' }
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={values => {
          const errors = {};
          if (values.mail &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
          ) {
            errors.mail = 'Email non valide';
          }
          
          if (!values.mail && !values.phone) {
            errors.message = 'Sans mail ni téléphone, nous ne pourrons pas vous contacter !';
          }
          return errors;
        }}
        onSubmit={handleSubmit}
      />
    </div>
    <div className={s['columns']}>
      { columns.map(col => (
        <div key={col.primary.name.text}>
          <header>{col.primary.name.text}</header>
          {col.items.map(l => (
            <Link key={l.link.uid} to={`/${l.link.uid}`}>{l.link_label.text}</Link>
          ))}
        </div>
      ))}
    </div>
  </div>
}

export default Footer;