import React, { useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Form, Modal } from 'src/styled-components'
import Flash from 'src/utils/flash'

import s from './ask-demo.module.css'

/* global fetch */
const AskDemo = ({ resolve, context }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site { siteMetadata { contactAction } }
        prismicHomepage { data { 
          demo_title { text }
          demo_intro { html }
          demo_btn
          context {
            ... on PrismicHomepageContextDemoContext {
              primary {
                context
                title,
                intro { html }
                btn
              }
            }
          }
        } }
      }
    `
  )
  const { contactAction } = data.site.siteMetadata;
  let { demo_title: { text: title }, demo_intro: { html: intro }, demo_btn: btn="Envoyer ma demande !" } = data.prismicHomepage.data;
  
  if (context) {
    const override = data.prismicHomepage.data.context.find(({ primary }) => primary.context === context)
    if (override) {
      title = override.primary.title || title
      intro = override.primary.intro.html || intro
      btn = override.primary.btn || btn
    }
  }
  
  const handleSubmit = useCallback(async (values, { setSubmitting, resetForm }) => {
    window.gtag && window.gtag('event', values.title, {
      'event_category': 'Forms',
      'event_label': "Envoi d'une demande de démo",
    });
    
    if (typeof fetch === 'undefined') {
      Flash(<div>
        <p>Votre navigateur est vraiment très agé !</p>
      </div>, { type:'error', duration: 10 })
      return
    }
    
    const response = await fetch(contactAction, {
  		method: 'POST',
  		body: JSON.stringify(values),
  		headers: {
  			'content-type': 'application/json'
  		}
  	});
  
  	if (!response.ok) {
      Flash(<div>
        <p>Erreur lors de l'envoi du formulaire</p>
      </div>, { type:'error', duration: 10 });
  	} else {
      resetForm();
      Flash(<div>
        <p>Votre demande a été prise en compte</p>
        <p>Nous allons vous contacter pour fixer un Rendez-vous</p>
      </div>, { duration: 10 });
      resolve()
  	}
  }, [ resolve, contactAction ]);
  
  
  return <Modal title={title}>
    <div className={s['askDemo']}>
      <div className={s['intro']} dangerouslySetInnerHTML={{ __html: intro }} />
      <Form schema={[
          {
            name: 'title',
            component: 'hidden',
            defaultValue: title
          },{
            label: 'Prénom',
            name: 'firstname',
            autofocus: true,
          },{
            label: 'Nom',
            name: 'lastname',
          },{
            label: 'Adresse email',
            name: 'mail',
          },{
            label: 'Téléphone',
            name: 'phone',
          },
        ]}
        action="#demo"
        btnLabel={btn}
        btnProps={{
          style: {
            gridColumnEnd: 'span 2',
            margin: '2em auto',
          }
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={values => {
          const errors = {};
          if (values.mail &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
          ) {
            errors.mail = 'Email non valide';
          }
          
          if (!values.mail && !values.phone) {
            errors.global = true;
            Flash('Sans mail ni téléphone, nous ne pourrons pas vous contacter !', 'error');
          }
          return errors;
        }}
        onSubmit={handleSubmit}
      />
    </div>
  </Modal>
}

export default AskDemo