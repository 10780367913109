import React, { useContext } from 'react'
import { motion } from 'framer-motion';

import { ModalContext } from 'src/utils/modal';
import { Button } from 'src/styled-components';

import s from './modal.module.css';

export const Modal = ({ title, children, style }) => {
  const { dismiss } = useContext(ModalContext)
  
  return <motion.div
    initial={{ translateY: -50, opacity: 0 }}
    animate={{ translateY: 0, opacity: 1 }}
    className={s['modal']}
    style={style}
  >
    <Button className={s['close']} aria-label="close" onClick={dismiss}>✕</Button>
    {title && <header className={s['header']}>{title}</header>}
    {children}
  </motion.div>
}