import React, { cloneElement, createContext, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { FillAbs } from 'src/styled-components';

import s from './modal.module.css';

let ref = {};

export const ModalContext = createContext();

export const ModalRenderer = ({ namespace='default' }) => {
  const nsref = useRef();
  useEffect(() => {
    const cur = nsref.current;
    ref[namespace] = cur;
    return () => ref[namespace] === cur && delete ref[namespace];
  }, [ nsref, namespace ])
  
  return <Modal ref={nsref} />
}


export default async function ModalService (children, options = {} ) {
  const { namespace = 'default' } = options;
  if (!ref[namespace]) throw new Error(`Unfound modal namespce ${namespace}`)
  
  return ref[namespace].addModal(children, options);
}

class Modal extends React.Component {
  constructor() {
    super();
    this.state ={ stack: [] };
    this.cpt = 0;
  }
  
  async addModal(children, options) {
    const modal = {
      id: ++this.cpt,
      options,
    };
    
    const res = await new Promise(resolve => {
      const dismiss = resolve.bind(null);
      Object.assign(modal, {
        resolve,
        dismiss,
        element: cloneElement(children, {resolve, dismiss}),
      })
      
      this.setState({
        stack: this.state.stack.concat([modal])
      })
    });
    
    const index = this.state.stack.indexOf(modal);
    if (index > -1) {
      const stack = this.state.stack.slice();
      stack.splice(index, 1);
      this.setState({ stack })
    }
    
    return res;
  }
  
  handleDismiss(dismiss, e) {
    if (e.currentTarget===e.target || e.currentTarget===e.target.parentNode) dismiss();
  }
  
  render() {
    return <AnimatePresence>
      {this.state.stack.map(({ id, resolve, dismiss, element }) => (
        <FillAbs
          Component={motion.div}
          key={id}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={s['container']}
          onMouseDown={this.handleDismiss.bind(this, dismiss)}
        >
          <div className={s['modalContainer']}>
            <div>
              <ModalContext.Provider value={{resolve, dismiss}}>
                {element}
              </ModalContext.Provider>
            </div>
          </div>
        </FillAbs>
      ))}
    </AnimatePresence>
  }
}